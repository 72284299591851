import classNames from 'classnames';
import React, { useState } from 'react';
import Select from 'react-select';

/* It wraps Select adding a local state to handle classname change when the select is not valid */
export const FormSelect = (props) => {
  const {
    onBlur = () => {},
    onChange = () => {},
    field,
    className,
    invalidClassName,
    selectInvalidCB = () => {},
    ...others
  } = props;

  const [selectInvalid, setSelectInvalid] = useState(false);
  // use this function to update the state, so it can propagate in the component above
  const setSelectInvalidDecorated = (newState) => {
    selectInvalidCB(newState);
    setSelectInvalid(newState);
  };

  return (
    <Select
      onChange={(newValue) => {
        setSelectInvalidDecorated(false);
        onChange(newValue);
      }}
      onBlur={() => {
        !field.value && setSelectInvalidDecorated(true);
        onBlur();
      }}
      classNamePrefix='select'
      className={classNames(className, {
        [invalidClassName]: selectInvalid,
      })}
      {...others}
    />
  );
};
