import { navigate } from 'gatsby';
import React from 'react';
import Infomessage from '../../../../molecules/infomessage/Infomessage';
import { phoneNumberRegex } from '../../../../utils/regexp';

export const ThirdSectorFeedBackError = ({ setShowFeedback }) => (
  <Infomessage
    type='error'
    text='Ci spiace, la tua richiesta non è andata a buon fine.'
    className='landing-form__infomessage'
    showButton={true}
    buttonText='Riprova'
    onClick={() => {
      setShowFeedback('');
    }}
  />
);
export const ThirdSectorFeedBackSuccess = ({
  setShowFeedback,
  successButtonText,
  successButtonLink = '',
}) => (
  <Infomessage
    type='success'
    text='La richiesta è stata inoltrata con successo'
    className='landing-form__infomessage'
    showButton={true}
    buttonText={successButtonText || 'Nuova richiesta'}
    onClick={() => {
      const navigateTo = successButtonLink.startsWith('/')
        ? successButtonLink
        : `/${successButtonLink}`;
      navigate(navigateTo);
    }}
  />
);

// unique ids for input and labels of the form
export const thirdSectorformIds = Object.freeze({
  'third-sector__business-name': 'third-sector__business-name',
  'third-sector__town': 'third-sector__town',
  'third-sector__province': 'third-sector__province',
  'third-sector__activity-type': 'third-sector__activity-type',
  'third-sector__mail': 'third-sector__mail',
  'third-sector__phone': 'third-sector__phone',
});

// field names used in react hook form and json
export const thirdSectorFormFieldNames = Object.freeze({
  companyName: 'companyName',
  city: 'city',
  province: 'province',
  businessScope: 'businessScope',
  email: 'email',
  phoneNumber: 'phoneNumber',

  // this field is not send with the form but it's useful to keep track of the last edited field between email and phone number
  // as we need to consider these fields mandatory or not if the other one is compiled
  lastEditedPseudoMandatoryField: 'lastEditedPseudoMandatoryField',
});

export const thirdSectorActivities = Object.freeze(
  [
    'Attività culturali e artistiche',
    'Attività sportive',
    'Attività ricreative e di socializzazione',
    'Istruzione e ricerca',
    'Sanità',
    'Assistenza sociale e protezione civile',
    'Ambiente',
    'Sviluppo economico e coesione sociale',
    'Tutela dei diritti e attività politica',
    'Filantropia e promozione del volontariato',
    'Cooperazione e solidarietà internazionale',
    'Religione',
    'Relazioni sindacali e rappresentanza interessi',
    'Altre attività',
  ].map((activity) => {
    return {
      label: activity,
      value: activity,
    };
  }, {})
);

export const formSubmitFactory = ({
  setLoading,
  formId,
  setShowFeedback,
  reset,
  captcha,
}) => {
  return () => async (data) => {
    const { lastEditedPseudoMandatoryField, ...dataToSubmit } = data;
    setLoading(true);

    const bodyToSend = JSON.stringify({
      formData: { ...dataToSubmit, formId },
      captcha: captcha,
      tipology: 'landing-form',
    });

    let sendResponse = {};
    try {
      sendResponse = await fetch(
        `${process.env.GATSBY_SERVICE_API_URL}/headless-api/orchestrator`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Accept: '*',
          },
          body: bodyToSend,
        }
      );
      if (!sendResponse.ok) {
        setLoading(false);
        setShowFeedback('error');
        reset();
        return;
      }
    } catch (e) {
      setShowFeedback('error');
      reset();
      setLoading(false);
      return;
    }

    setLoading(false);
    setShowFeedback('submitted');
    reset();
  };
};

export const areMailAndPhoneMandatory = (watch) => {
  const isPhoneMandatory = (() => {
    const email = watch(thirdSectorFormFieldNames.email);
    const phoneNumber = watch(thirdSectorFormFieldNames.phoneNumber);
    const lastEditedPseudoMandatoryField = watch(
      thirdSectorFormFieldNames.lastEditedPseudoMandatoryField
    );
    if (
      /^\S+@\S+$/i.test(email) &&
      (lastEditedPseudoMandatoryField ===
        thirdSectorFormFieldNames.phoneNumber ||
        !phoneNumber)
    ) {
      return false;
    }
    return true;
  })();

  const isEmailMandatory = (() => {
    const phoneNumber = watch(thirdSectorFormFieldNames.phoneNumber);
    const email = watch(thirdSectorFormFieldNames.email);
    const lastEditedPseudoMandatoryField = watch(
      thirdSectorFormFieldNames.lastEditedPseudoMandatoryField
    );
    if (
      phoneNumberRegex.test(phoneNumber) &&
      (lastEditedPseudoMandatoryField === thirdSectorFormFieldNames.email ||
        !email)
    ) {
      return false;
    }
    return true;
  })();

  return { isPhoneMandatory, isEmailMandatory };
};
