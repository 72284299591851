import classNames from 'classnames';
import React, { useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { useForm } from 'react-hook-form';

import ThirdSectorSubform from './subforms/thirdSectorSubForm/ThirdSectorSubform';
import {
  ThirdSectorFeedBackError,
  ThirdSectorFeedBackSuccess,
} from './subforms/thirdSectorSubForm/helpers';

import { ButtonSubmit } from '../button/Button';

import Spinner from '../../atoms/spinner/spinner';
import AnchorHooking from '../../molecules/anchor-hooking/AnchorHooking';
import Title from '../../molecules/title/title';

import { Wrapper } from '../../utils/components';
import { getCommonOrganismData } from '../../utils/functions';
import withPreview from '../../utils/with-preview';

import './LandingForm.scss';

const GOOGLE_RECAPTCHA_KEY = process.env.GATSBY_GOOGLE_RECAPTCHA_KEY;

const LandingForm = ({ data, considerMenuSpace }) => {
  const {
    htmlTagWrapper,
    backgroundColor,
    anchorLabel,
    marginTop,
    title,
    titleHeadingTag,
    titleIconThickness,
  } = getCommonOrganismData(data);

  const [captcha, setCaptcha] = useState('');
  const [onSubmit, setOnSubmit] = useState(null);
  const [loading, setLoading] = useState(false);

  const [showFeedback, setShowFeedback] = useState('');

  const methods = useForm({
    mode: 'all',
    reValidateMode: 'onChange',
  });

  const isValid = methods.formState.isValid;

  // note: this field has a different name because the query does not work properly without renaming the field itself
  const titleIconId =
    data?.moduleTitle?.content?.iconSvgIdTitle?.valueString || '';

  const formId = data?.landingFormId?.value[0];

  const privacyPolicyDocument =
    data?.privacyPolicyDocument?.node?.contentUrl || '';
  const subTitle = data?.moduleTitle?.content?.subtitle?.value;

  const successButtonText = data?.successButtonText?.value || '';
  const successButtonLink =
    data?.successButtonText?.content?.successButtonLink?.value || '';

  // handle fallback
  if (!formId) return null;
  return (
    <Wrapper
      tag={htmlTagWrapper}
      data-module-name='Landing-form'
      data-module-code='C-106'
      className={classNames(
        `landing-form wrapper wrapper--underflow ${marginTop}`
      )}
      style={{
        '--bg': `var(--${backgroundColor})`,
      }}
    >
      <AnchorHooking id={anchorLabel} belowMenu={considerMenuSpace} />
      {(title || titleIconId) && (
        <Title
          tag={titleHeadingTag}
          titleIconId={titleIconId}
          titleIconThickness={titleIconThickness}
          titleOutro={subTitle}
        >
          {title}
        </Title>
      )}

      {/* This is shown when the form is submitted */}
      {/* TODO: extract in a switch-case whenever we need other form feedbacks */}
      {showFeedback === 'error' && (
        <ThirdSectorFeedBackError setShowFeedback={setShowFeedback} />
      )}
      {showFeedback === 'submitted' && (
        <ThirdSectorFeedBackSuccess
          setShowFeedback={setShowFeedback}
          successButtonText={successButtonText}
          successButtonLink={successButtonLink}
        />
      )}

      {/* This is shwon when the form is submitting or the user is filling it */}
      {showFeedback ? null : loading ? (
        <Spinner className='landing-form__spinner' />
      ) : (
        <div
          className={classNames('landing-form__fields', {
            'landing-form__fields--third-sector': true, // TODO: tune this adding a check with form type whenever additional forms will be created'>
          })}
        >
          <form
            className={classNames('landing-form__form')}
            onSubmit={methods.handleSubmit(onSubmit)}
          >
            {/* TODO: add switch-case for multiple form types, whenever necessary */}
            <>
              <ThirdSectorSubform
                formId={formId}
                captcha={captcha}
                errors={methods.formState.errors}
                reset={methods.reset}
                setShowFeedback={setShowFeedback}
                setLoading={setLoading}
                setValue={methods.setValue}
                watch={methods.watch}
                setOnSubmit={setOnSubmit}
                register={methods.register}
                control={methods.control}
                privacyPolicyDocument={privacyPolicyDocument}
                getValues={methods.getValues}
              />
            </>

            <ReCAPTCHA sitekey={GOOGLE_RECAPTCHA_KEY} onChange={setCaptcha} />
            <div className={'landing-form__submit'}>
              <ButtonSubmit
                disabled={!isValid || !captcha}
                text='Invia'
                inputClassName={'landing-form__submit-input'}
                inputRole='submit'
                htmlFor={'landing-form-submit'}
              />
            </div>
          </form>
        </div>
      )}
    </Wrapper>
  );
};

export default withPreview(LandingForm);
